import path from 'path';
import { CreatePagesArgs } from 'gatsby';
import type { AllContentfulContentPage, AllContentfulRetailer, AllContentfulSampleServicePage, ContentfulContentPage } from '@models';
import { parseJSON, prepareLocale } from './commonHelper';
import { checkRetailerEnabled, checkRetailerHasLocale, checkRetailerHasPage, pageTypes, prepareRetailerName } from './retailersHelper';
import UrlHelper, { urlPageTypes } from './urlHelper';
import { mapGridTiles, mapWizards } from './mappingHelper';

interface Route {
    path: string,
    component: string,
    context: any
}

export const getGridRoutes = async (graphql: CreatePagesArgs['graphql']) => {
    const { data } = await graphql<AllContentfulRetailer>(`
        fragment DecisionTreeQuestion on ContentfulDecisionTreeQuestion {
            title
            description {
                raw
            }
            gridFilter {
                code
                description
                label
                tooltip {
                    raw
                }
                type
            }
        }

        fragment DecisionTreeAnswer on ContentfulDecisionTreeAnswer {
            title
            attributeValue
            description {
                raw
            }
            tooltip {
                raw
            }
            image {
                file {
                    url
                }
            }
        }

        query {
            allContentfulRetailer {
                nodes {
                    name
                    node_locale
                    headerTitle
                    headerDescription
                    headerUsp
                    channel
                    headerFilters {
                        label
                        filterValue
                        filter {
                            code
                        }
                        image {
                            file {
                                url
                            }
                        }
                    }
                    gridFilters {
                        code
                        type
                        label
                        description
                        tooltip {
                            raw
                        }
                        referrerMappings {
                            internal {
                                content
                            }
                        }
                    }
                    settings {
                        logo {
                            file {
                                url
                            }
                        }
                    }
                    gridTiles {
                        ... on Node {
                            ... on ContentfulGridContentTile {
                                id
                                internal {
                                    type
                                }
                                gridFilters {
                                    filter {
                                        code
                                    }
                                    filterValue
                                }
                                position
                                title

                                label
                                labelColor
                                image {
                                    file {
                                        url
                                    }
                                }
                                contentPageUrl {
                                    url
                                }
                            }
                            ... on ContentfulGridFeedbackTile {
                                id
                                internal {
                                    type
                                }
                                gridFilters {
                                    filter {
                                        code
                                    }
                                    filterValue
                                }
                                position
                                title

                                description
                                type
                                thankYouText
                            }
                        }
                    }
                    wizards {
                        internal {
                            type
                        }
                        name
                        position
                        question {
                            ...DecisionTreeQuestion
                            answers {
                                ...DecisionTreeAnswer
                                question {
                                    ...DecisionTreeQuestion
                                    answers {
                                        ...DecisionTreeAnswer
                                        question {
                                            ...DecisionTreeQuestion
                                            answers {
                                                ...DecisionTreeAnswer
                                                question {
                                                    ...DecisionTreeQuestion
                                                    answers {
                                                        ...DecisionTreeAnswer
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    if (!data) return [];

    const NON_LINGUAL_ATTRIBUTES = [
        'table_legs_possible',
        'plateau_possible'
    ];

    let routes: Route[] = [];

    data.allContentfulRetailer.nodes.forEach(retailer => {
        const retailerName = prepareRetailerName(retailer.name);

        if (!retailer.channel) return;
        if (!checkRetailerEnabled(retailerName)) return;
        if (!checkRetailerHasPage(retailerName, pageTypes.Catalog)) return;
        if (!checkRetailerHasLocale(retailerName, retailer.node_locale)) return;

        let attributeCodes: string[] = [];

        retailer.gridFilters.forEach(filter => {
            if (filter.referrerMappings?.internal?.content)
                filter.referrerMappings = parseJSON(filter.referrerMappings.internal.content);

            switch (filter.type) {
                case 'Thickness':
                    attributeCodes.push('product_thickness');
                    break;
                case 'Attribute':
                    attributeCodes.push(
                        NON_LINGUAL_ATTRIBUTES.includes(filter.code)
                            ? filter.code
                            : filter.code + `_${ prepareLocale(retailer.node_locale) }`
                    );
                    break;
                case 'Price':
                    attributeCodes.push('min_price');
                    attributeCodes.push('max_price');
                    break;
            }
        });

        routes.push({
            path: UrlHelper.getUrl(retailer.name, retailer.node_locale),
            component: path.resolve(`./src/templates/retailer/${ retailerName }/CatalogPage.tsx`),
            context: {
                pageType: pageTypes.Catalog,
                retailer: retailer.name,
                locale: prepareLocale(retailer.node_locale),
                channel: retailer.channel,
                filters: retailer.gridFilters,
                filterCodes: attributeCodes,
                headerContent: {
                    title: retailer.headerTitle,
                    description: retailer.headerDescription,
                    usp: retailer.headerUsp,
                    filters: retailer.headerFilters
                },
                retailerLogo: retailer.settings?.logo?.file?.url,
                limit: Number(process.env.GATSBY_GRID_LIMIT),
                tiles: mapGridTiles(retailer, retailer.gridTiles ?? []),
                wizards: mapWizards(retailer.wizards ?? [])
            }
        });
    });

    return routes;
};

export const getProductRoutes = async (graphql: CreatePagesArgs['graphql']) => {
    const { data } = await graphql<AllContentfulRetailer>(`
        query {
            allContentfulRetailer {
                nodes {
                    node_locale
                    name
                    channel
                    settings {
                        logo {
                            file {
                                url
                            }
                        }
                    }
                }
            }
            allThujaProductCando { nodes { url } }
            allThujaProductHubo { nodes { url } }
            allThujaProductGamma { nodes { url } }
            allThujaProductGammaBe { nodes { url } }
            allThujaProductKarwei { nodes { url } }
            allThujaProductForestea { nodes { url } }
            allThujaProductPraxis { nodes { url } }
            allThujaProductBrico { nodes { url } }
            allThujaProductHornbach { nodes { url } }
            allThujaProductHuboInsects { nodes { url } }
            allThujaProductHornbachInsects { nodes { url } }
            allThujaProductNoName { nodes { url } }
            allThujaProductHuboNl { nodes { url } }
            allThujaProductGammaStairs { nodes { url } }
            allThujaProductGammaBeStairs { nodes { url } }
            allThujaProductKarweiStairs { nodes { url } }
            allThujaProductPraxisStairs { nodes { url } }
            allThujaProductBricoStairs { nodes { url } }
            allThujaProductBricoInsects { nodes { url } }
            allThujaProductPraxisInsects { nodes { url } }
            allThujaProductHornbachStairs { nodes { url } }
            allThujaProductCandoCabinets { nodes { url } }
            allThujaProductGammaCabinets { nodes { url } }
            allThujaProductKarweiCabinets { nodes { url } }
            allThujaProductHuboCabinets { nodes { url } }
            allThujaProductBricoCabinets { nodes { url } }
            allThujaProductPraxisCabinets { nodes { url } }
            allThujaProductHornbachCabinets { nodes { url } }
            allThujaProductEntrepot { nodes { url } }
            allThujaProductMrBricolageStairs { nodes { url } }
            allThujaProductHuboStairs { nodes { url } }
            allThujaProductRaffito { nodes { url } }
            allThujaProductFlexscreen { nodes { url } }
        }
    `);

    if (!data)
        throw new Error("an error occurred: no data in 'getProductRoutes'");

    let routes: Route[] = [];

    data.allContentfulRetailer.nodes.forEach(retailer => {
        const retailerName = prepareRetailerName(retailer.name);

        if (!retailer.channel) return;
        if (!checkRetailerEnabled(retailerName)) return;
        if (!checkRetailerHasPage(retailerName, pageTypes.PDP)) return;
        if (!checkRetailerHasLocale(retailerName, retailer.node_locale)) return;

        data[`allThujaProduct${ retailerName }`].nodes.forEach(product => {
            routes.push({
                path: UrlHelper.getUrl(retailer.name, retailer.node_locale, urlPageTypes.Product, product.url),
                component: path.resolve(`./src/templates/retailer/${ retailerName }/Product.tsx`),
                context: {
                    pageType: pageTypes.PDP,
                    retailer: retailer.name,
                    channel: retailer.channel,
                    locale: prepareLocale(retailer.node_locale),
                    url: product.url,
                    retailerLogo: retailer.settings?.logo?.file?.url
                }
            });
        });
    });

    return routes;
};

export const getSawListRoutes = async (graphql: CreatePagesArgs['graphql']) => {
    const { data } = await graphql<AllContentfulRetailer>(`
        query {
            allContentfulRetailer {
                nodes {
                    node_locale
                    name
                    channel
                    settings {
                        logo {
                            file {
                                url
                            }
                        }
                        url
                        urlLabel
                        beRetailersSites {
                            label
                            url
                        }
                        nlRetailersSites {
                            label
                            url
                        }
                        checkoutProcessInformation{
                            raw
                        }
                        checkoutProcessUsp
                    }
                }
            }
        }
    `);

    if (!data) return [];

    let routes: Route[] = [];

    data.allContentfulRetailer.nodes.forEach(retailer => {
        const retailerName = prepareRetailerName(retailer.name);

        if (!retailer.channel) return;
        if (!checkRetailerEnabled(retailerName)) return;
        if (!checkRetailerHasPage(retailerName, pageTypes.SawList)) return;
        if (!checkRetailerHasLocale(retailerName, retailer.node_locale)) return;

        routes.push({
            path: UrlHelper.getUrl(retailer.name, retailer.node_locale, urlPageTypes.SawList),
            component: path.resolve(`./src/templates/SawList.tsx`),
            context: {
                pageType: pageTypes.SawList,
                retailer: retailer.name,
                channel: retailer.channel,
                locale: prepareLocale(retailer.node_locale),
                retailerLogo: retailer.settings?.logo?.file?.url,
                retailerSettings: retailer.settings
            }
        });
    });

    return routes;
};

export const getContentPageRoutes = async (graphql: CreatePagesArgs['graphql']) => {
    const { data } = await graphql<AllContentfulContentPage>(`
        query {
            allContentfulContentPage {
                nodes {
                    url
                    retailers {
                        name
                        node_locale
                    }
                }
            }
        }
    `);

    if (!data) return [];

    let routes: Route[] = [];

    data.allContentfulContentPage.nodes.forEach((contentPage: ContentfulContentPage) => {
        if (!Array.isArray(contentPage.retailers)) return;
        contentPage.retailers.forEach(retailer => {
            const retailerName = prepareRetailerName(retailer.name);

            if (!checkRetailerEnabled(retailerName)) return;
            if (!checkRetailerHasPage(retailerName, pageTypes.Content)) return;
            if (!checkRetailerHasLocale(retailerName, retailer.node_locale)) return;

            routes.push({
                path: UrlHelper.getUrl(retailer.name, retailer.node_locale, urlPageTypes.ContentPage, contentPage.url),
                component: path.resolve('./src/templates/ContentPage.tsx'),
                context: {
                    pageType: pageTypes.Content,
                    retailer: retailer.name,
                    locale: prepareLocale(retailer.node_locale),
                    queryLocale: retailer.node_locale,
                    url: contentPage.url
                }
            });
        });
    });

    return routes;
};

export const getSampleServicePageRoutes = async (graphql: CreatePagesArgs['graphql']) => {
    const { data } = await graphql<AllContentfulSampleServicePage>(`
        query {
            allContentfulSampleServicePage {
                nodes {
                    urlSlug
                    retailers {
                        name
                        node_locale
                    }
                }
            }
        }
    `);

    if (!data) return [];

    let routes: Route[] = [];

    data.allContentfulSampleServicePage.nodes.forEach((page) => {
        if (!Array.isArray(page.retailers)) return;
        page.retailers.forEach(retailer => {
            const retailerName = prepareRetailerName(retailer.name);

            if (!checkRetailerEnabled(retailerName)) return;
            if (!checkRetailerHasPage(retailerName, pageTypes.SampleService)) return;
            if (!checkRetailerHasLocale(retailerName, retailer.node_locale)) return;

            routes.push({
                path: UrlHelper.getUrl(retailer.name, retailer.node_locale, urlPageTypes.SampleService, page.urlSlug),
                component: path.resolve('./src/templates/SampleServicePage.tsx'),
                context: {
                    pageType: pageTypes.SampleService,
                    retailer: retailer.name,
                    locale: prepareLocale(retailer.node_locale),
                    queryLocale: retailer.node_locale,
                    url: page.urlSlug
                }
            });
        });
    });

    return routes;
};
