import { createTheme } from '@mui/material';
import { merge } from 'lodash';

let palette = {
    text: {
        primary: '#000',
        secondary: '#3c3c3c',
        disabled: '#a8b3bf',
    },
    gray: {
        main: '#ccc',
        light: '#c4c4c4',
        lightOne: '#cfcecf',
        lightTwo: '#bbb',
        lightThree: '#516371',
        lightFour: '#e4e4e4',
        dark: '#71767a',
        darkOne: '#3f484a',
        darkTwo: '#1b1b1f',
    },
    grey: {
        300: '#71767a',
    },
    white: {
        main: '#fff',
        light: '#fbf8fd',
    },
    blue: {
        main: '#d8dadf',
        light: '#505971',
        dark: '#006689',
    },
    black: {
        main: '#000',
    },
    divider: '#c4c4c4',
    tonalOffset: 0.1,
    checklist: {
        main: '#6d808d',
        light: '#a1b5c5',
    },
    grayscale: {
        main: '#f8f8f8',
    },
    tile: {
        main: '#d4d2c9',
        input: '#fff',
        text: '#000',
        error: '#e44',
        success: '#74c200',
    },
    discount: {
        text: '#ff7300',
        label: '#ff7300',
    },
    icons: {
        usp: '#74c200',
    },
    error: {
        main: '#dc3545',
        dark: '#e44',
    },
    wizard: {
        dark: '#777'
    }
};

const BaseThemeOption = {
    palette: {
        ...palette,
        configurator: {
            step: {
                background: {
                    primary: palette.white.main,
                },
            },
            toggleMore: {
                text: {
                    primary: palette.text.primary
                }
            },
            tooltip: {
                text: {
                    primary: palette.text.primary
                }
            },
            checkbox: {
                primary: palette.gray.lightTwo,
                active: palette.gray.dark
            },
            cta: {
                main: {
                    background: {
                        primary: palette.gray.dark
                    },
                    text: {
                        primary: palette.gray.lightTwo
                    },
                },
                disabled: {
                    background: {
                        primary: palette.gray.lightFour
                    },
                    text: {
                        primary: palette.gray.main
                    },
                }
            },
            options: {
                button: {
                    background: {
                        primary: palette.white.main,
                        active: palette.white.main
                    },
                    border: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                    text: {
                        main: {
                            primary: palette.text.primary,
                            active: palette.text.primary
                        },
                        second: {
                            primary: palette.text.primary,
                            active: palette.text.primary
                        }
                    }
                },
                chip: {
                    background: {
                        primary: palette.white.main,
                        active: palette.gray.dark
                    },
                    border: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                    text: {
                        primary: palette.text.primary,
                        active: palette.gray.main
                    }
                },
                radio: {
                    background: {
                        primary: palette.white.main,
                        active: palette.white.main
                    },
                    border: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                    text: {
                        main: {
                            primary: palette.text.primary,
                            active: palette.text.primary
                        },
                        second: {
                            primary: palette.text.primary,
                            active: palette.text.primary
                        }
                    },
                    control: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                },
                card: {
                    background: {
                        primary: palette.white.main,
                        active: palette.white.main
                    },
                    border: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                    text: {
                        main: {
                            primary: palette.text.primary,
                            active: palette.text.primary
                        },
                        second: {
                            primary: palette.gray.lightThree,
                            active: palette.gray.lightThree
                        }
                    }
                },
                check: {
                    background: {
                        primary: palette.white.main,
                        active: palette.white.main
                    },
                    border: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                    text: {
                        primary: palette.text.primary,
                        active: palette.text.primary
                    }
                },
                tile: {
                    background: {
                        primary: palette.white.main,
                        active: palette.white.main
                    },
                    border: {
                        primary: palette.gray.lightTwo,
                        active: palette.gray.dark
                    },
                    text: {
                        main: {
                            primary: palette.text.primary,
                            active: palette.text.primary
                        }
                    },
                    mark: {
                        background: {
                            primary: palette.gray.lightTwo,
                            active: palette.gray.dark
                        },
                        svg: {
                            primary: palette.white.main,
                            active: palette.white.main
                        }
                    },
                    label: {
                        background: {
                            primary: palette.gray.lightTwo,
                            active: palette.gray.dark
                        },
                        text: {
                            primary: palette.white.main,
                            active: palette.white.main
                        }
                    }
                },
                slider: {
                    background: {
                        primary: palette.white.main,
                        active: palette.white.main
                    },
                    text: {
                        primary: palette.text.primary,
                        active: palette.text.primary
                    },
                    rail: {
                        background: {
                            primary: palette.gray.lightTwo
                        }
                    },
                    mark: {
                        background: {
                            primary: palette.gray.dark
                        }
                    },
                    thumb: {
                        background: {
                            primary: palette.gray.dark
                        }
                    },
                    valueLabel: {
                        background: {
                            primary: palette.gray.dark
                        },
                        text: {
                            primary: palette.white.main
                        },
                    },
                    button: {
                        svg: {
                            primary: palette.gray.dark
                        }
                    },
                },
            },
            gallery: {
                controls: {
                    background: {
                        primary: '#14181f99'
                    },
                    svg: {
                        primary: palette.white.main
                    },
                },
                bullets: {
                    background: {
                        primary: '#ffffff99'
                    }
                },
                imageBullet: {
                    background: {
                        primary: palette.black.main
                    },
                    border: {
                        primary: palette.black.main
                    }
                },
                viewButton: {
                    background: {
                        primary: '#14181f99'
                    }
                },
            },
            hr: {
                border: {
                    primary: palette.gray.lightTwo
                }
            },
            title: {
                text: {
                    primary: palette.text.primary
                }
            },
        }
    },
    typography: {
        fontSize: 14,
        h4: {
            fontWeight: 600,
            fontSize: '2.25rem'
        },
        h6: {
            fontSize: '1.15rem'
        },
        configurator: {
            h3: {
                fontSize: '1.375rem'
            },
            h5: {
                fontSize: '1.13rem'
            },
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 423,
            md: 680,
            lg: 991,
            xl: 1336,
        },
    }
};

export default createTheme(BaseThemeOption);

export const createFontFamily = (fontFamily: string, options: any = {}) => merge({
        fontFamily,
        h1: { fontFamily },
        h2: { fontFamily },
        h3: { fontFamily },
        h4: { fontFamily },
        h5: { fontFamily },
        h6: { fontFamily },
        subtitle1: { fontFamily },
        subtitle2: { fontFamily },
        body1: { fontFamily },
        body2: { fontFamily },
        button: { fontFamily },
        caption: { fontFamily },
        overline: { fontFamily },
    },
    options,
);
