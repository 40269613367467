import { orderBy, uniqBy, find } from 'lodash';
import { GridTiles, prepareLocale, productLines, WizardTypes, locales } from './commonHelper';
import MicrocopyHelper from './microcopyHelper';
import {
    IContentfulGridTile,
    IContentfulGridTileBase,
    IDecisionTreeAnswer,
    IDecisionTreeQuestion,
    IDecisionTreeWizard,
    Wizard
} from '@models';
import type { CDNImages } from '@models/Common';

export const mapFilters = filters => {
    const result: any[] = [];

    Object.keys(filters).forEach(filter => {
        result.push({
            name: filter,
            values: !Array.isArray(filters[filter])
                ? [ { title: filter, value: filters[filter] } ]
                : filters[filter].map(item => ({ title: item.key, value: item.doc_count }))
        });
    });

    return result;
};

export const mapCdnImage = (key: string, type: string = 'original') => {
    if (!key?.length) return '';

    const baseAttributes = {
        bucket: new URL(String(process.env.GATSBY_PRODUCT_IMAGES_URL)).host,
        key: `images/products/${ key }`
    };

    let edits: Record<string, any>;
    switch (type) {
        case 'plp':
            edits = {
                resize: {
                    width: 420,
                    height: 300,
                    fit: 'cover',
                    quality: 100,
                    background: { r: 255, g: 255, b: 255, alpha: 1 }
                }
            };
            break;
        case 'pdp':
            edits = {
                resize: {
                    width: 550,
                    fit: 'inside',
                    layout: 'constrained'
                }
            };
            break;
        case 'pdp_mobile':
            edits = {
                resize: {
                    width: 450 * 2,
                    height: 340 * 2,
                    fit: 'inside',
                    layout: 'fixed'
                }
            };
            break;
        case 'pdp_thumbnail':
            edits = {
                resize: {
                    width: 80,
                    height: 80,
                    quality: 80
                }
            };
            break;
        case 'pdp_landscape':
            edits = {
                resize: {
                    width: 750,
                    fit: 'inside',
                    layout: 'constrained'
                }
            };
            break;
        case 'original':
        default:
            edits = {};
    }

    const encodedObject = btoa(JSON.stringify({
        ...baseAttributes,
        edits
    }));

    /* ToDo: move to variable if needed (currently, there is 1 single CDN for all environments). */
    return `https://cdn.deli-microsite.prod.sites.spheremall.com/${ encodedObject }`;
};

const mapCdnImages = (images: { path: string }[]): CDNImages => {
    if (images[0]?.path) {
        return {
            plp: mapCdnImage(images[0]?.path, 'plp'),
            pdp: uniqBy(
              images.map((image: { path: string }) => ({
                  original: mapCdnImage(image.path),
                  main: mapCdnImage(image.path, 'pdp'),
                  mobile: mapCdnImage(image.path, 'pdp_mobile'),
                  thumbnail: mapCdnImage(image.path, 'pdp_thumbnail'),
                  landscape: mapCdnImage(image.path, 'pdp_landscape'),
              })),
              'main'
            )
        };
    }

    const path = '_blank.jpg';

    return {
        plp: mapCdnImage(path, 'plp'),
        pdp: [{
            original: mapCdnImage(path),
            main: mapCdnImage(path, 'pdp'),
            mobile: mapCdnImage(path, 'pdp_mobile'),
            thumbnail: mapCdnImage(path, 'pdp_thumbnail'),
            landscape: mapCdnImage(path, 'pdp_landscape'),
        }]
    };
}

export const mapProducts = (data: any) => data.map(({ id, attributes }) => {
    attributes.images = orderBy(attributes.images, [ 'order_number' ], [ 'asc' ]);
    attributes.relevance = Object.values(attributes.relevance)[0];
    attributes.ean = String(attributes.ean);
    attributes.article = Number(attributes.article || 0);
    attributes.article_height = Number(attributes.article_height || 0);
    attributes.number_doors = Number(attributes.number_doors || 0);
    attributes.configurator_input_max_length = Number(attributes.configurator_input_max_length || 0);
    attributes.configurator_input_max_width = Number(attributes.configurator_input_max_width || 0);
    attributes.dealer_price_factor = Number(attributes.dealer_price_factor || 0);
    attributes.starting_rate = Number(attributes.starting_rate || 0);
    attributes.thuja_variants = attributes.thuja_variants || [];

    attributes.cdn = mapCdnImages(attributes.images);
    attributes.product_logo = mapCdnImage(attributes.product_logo); // ToDo: legacy, should be removed
    attributes.product_logo_pdp = mapCdnImage(attributes.product_logo_pdp);
    attributes.product_logo_plp = mapCdnImage(attributes.product_logo_plp);

    const toArray = (value: any) => Array.isArray(value) ? value : (value ? [ value ] : []);

    locales.forEach(locale => {
        attributes[`usage_for_${ locale }`] = toArray(attributes[`usage_for_${ locale }`]);
        attributes[`table_shape_${ locale }`] = attributes[`table_shape_${ locale }`] || '';
    });

    return {
        id,
        url: id,
        ...attributes,
    };
});

export const mapFilterValue = (microcopy: MicrocopyHelper, attribute: string, value: string): string => {
    const
        yes = microcopy.get('catalog.filter.table_legs_possible.value.yes', 'Yes'),
        no = microcopy.get('catalog.filter.table_legs_possible.value.no', 'No');

    const mapping: Record<string, Record<string, string>> = {
        table_legs_possible: { yes, no },
        plateau_possible: {
            Yes: yes,
            No: no,
        },
    };

    return mapping[attribute]?.[value] || value;
};

export const mapProductLine = (productLine: string) => {
    if (!productLine) return;

    const
        availableProductLines = [
            productLines.tables,
            productLines.decowall,
            productLines.cabinets,
            productLines.insect,
            productLines.worktops,
            productLines.stairs,
            productLines.flexscreen,
        ],
        value = productLine.toLowerCase();

    return availableProductLines.find(productLine => value.includes(productLine)) ?? productLines.timber;
};

export const getReferenceId = (productInfo: Record<string, any>, productLine: string) => {
    switch (mapProductLine(productLine)) {
        case productLines.stairs:
            return productInfo.virtualProductId ?? productInfo.ean;
        case productLines.cabinets:
            return (productInfo?.portal ?? productInfo?.cabinet)?.productInfo?.ean;
        default:
            return productInfo.ean;
    }
};

export const mapGridTiles = (retailer: any, tiles: any[]): IContentfulGridTile[] =>
    tiles?.map(tile => {
        if (!tile.internal?.type) return tile;

        const commonAttributes: IContentfulGridTileBase = {
            id: tile.id,
            entryType: tile.internal?.type,
            title: tile.title,
            filters: tile.gridFilters?.map((filter: { filter: { code: string }, filterValue: string }) => ({
                [filter.filter.code + `_${ prepareLocale(retailer.node_locale) }`]: filter.filterValue
            })) ?? [],
            position: tile.position
        };

        switch (tile.internal.type) {
            case GridTiles.contentTile:
                return {
                    ...commonAttributes,
                    label: {
                        name: tile.label,
                        color: tile.labelColor
                    },
                    image: tile.image.file.url,
                    contentPageUrl: tile.contentPageUrl.url,
                };
            case GridTiles.feedbackTile:
                return {
                    ...commonAttributes,
                    description: tile.description,
                    type: tile.type,
                    thankYouText: tile.thankYouText
                };
            default:
                return commonAttributes;
        }
    });

export const mapWizards = (wizards: (Wizard & Record<string, any>)[]): Wizard[] => wizards.map(wizard => {
    if (!wizard.internal.type) return wizard;
    switch (wizard.internal.type) {
        case WizardTypes.decisionTree:
            return mapDecisionTree(wizard);
        default:
            return wizard;
    }
});

const mapDecisionTree = (wizard: IDecisionTreeWizard): IDecisionTreeWizard => {
    if (Array.isArray(wizard.question) && wizard.question.length > 0) {
        wizard.question = mapDecisionTreeQuestion(wizard.question[0]);
    }

    return wizard;
}

const mapDecisionTreeQuestion = (question: IDecisionTreeQuestion): IDecisionTreeQuestion => {
    question.answers = question.answers.map(answer => mapDecisionTreeAnswer(answer));

    return question;
}

const mapDecisionTreeAnswer = (answer: IDecisionTreeAnswer): IDecisionTreeAnswer => {
    if (Array.isArray(answer.question) && answer.question.length > 0) {
        answer.question = mapDecisionTreeQuestion(answer.question[0]);
    }

    return answer;
}

export const mapFilterNameByLocale = (filters: Record<string, any>, code: string, locale: string) => {
    const data = find(filters, filter => filter.name === `${ code }_${ locale }`);

    if (data) {
        return {
            filterCode: `${ code }_${ locale }`,
            data
        };
    }

    return {
        filterCode: code,
        data: find(filters, filter => filter.name === code)
    };
};
