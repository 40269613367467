import { createTheme } from '@mui/material';
import ThemeComponents from './base/components';
import { NoNameTheme } from '@themes';

let flexscreenTheme = {
    palette: {
        wizard: {
            dark: '#F99D27'
        }
    }
};

let theme: any = createTheme(NoNameTheme, flexscreenTheme);

export default createTheme(theme, ThemeComponents(theme));
