import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import MuseoSansLight from '@fonts/MuseoSansLight.woff2';
import MuseoSansRegular from '@fonts/MuseoSansRegular.woff2';
import MuseoSansBold from '@fonts/MuseoSansBold.woff2';

const primaryMain = '#6e6259';

const palette = {
    primary: {
        main: primaryMain,
        light: '#aca39a',
        lightTwo: '#2c2a29',
        dark: '#000'
    },
    secondary: {
        main: '#aca39a'
    },
    white: {
        main: '#fff'
    },
    wizard: {
        dark: primaryMain
    }
};

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: '#89b203'
    },
    cta: {
        main: {
            background: {
                primary: 'linear-gradient(to bottom,rgba(154,189,0,1) 0,rgba(72,164,22,1) 100%)'
            },
            text: {
                primary: palette.white.main
            },
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            },
            text: {
                second: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            }
        },
        chip: {
            background: {
                active: palette.primary.lightTwo
            },
            border: {
                active: palette.primary.lightTwo
            },
            text: {
                active: '#78909c'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
            text: {
                second: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            },
        },
        card: {
            border: {
                active: palette.primary.main
            },
            text: {
                main: {
                    primary: '#37474f',
                    active: '#37474f'
                },
                second: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            text: {
                main: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#37474f',
                    active: '#37474f'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            text: {
                primary: '#78909c',
                active: '#78909c'
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            },
            button: {
                svg: {
                    primary: '#78909c'
                }
            },
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let raffitoTheme = {
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily(
            'MuseoSansRegular',
            {
                fontSize: 18,
                h4: {
                    fontSize: '2rem',
                },
                h5: {
                    fontSize: '1.5rem',
                },
            },
        ),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'MuseoSansRegular';
                  src: url('${ MuseoSansRegular }') format('woff2');
                }
                @font-face {
                  font-family: 'MuseoSansBold';
                  src: url('${ MuseoSansBold }') format('woff2');
                }
                @font-face {
                  font-family: 'MuseoSansLight';
                  src: url('${ MuseoSansLight }') format('woff2');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-contained': {
                        color: palette.white.main,
                        textTransform: 'uppercase',
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.promo-label': {
                        color: palette.white,
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li:first-of-type, li:last-of-type': {
                            '.MuiButtonBase-root': {
                                color: palette.primary.main,
                            },
                        },
                        'li': {
                            '.MuiPaginationItem-root': {
                                fontSize: '1rem',
                                border: 0,
                                color: palette.primary.main,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: 'transparent!important',
                                        border: 0,
                                        color: palette.primary.main,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};

let theme: any = createTheme(BaseTheme, raffitoTheme);

export default createTheme(theme, ThemeComponents(theme));
