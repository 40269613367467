import { alpha, createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Akkurat from '@fonts/Akkurat-Regular.woff2';
import AkkuratBold from '@fonts/Akkurat-Bold.woff2';
import doneIcon from '../images/done_icon.svg'

const primaryMain = '#111316';

let palette = {
    primary: {
        main: primaryMain,
        mainTwo: '#C20344',
        light: primaryMain,
        lightTwo: '#f9eaef',
        lightThree: '#e7e7e7',
        dark: primaryMain,
        darken: '#00144b'
    },
    secondary: {
        main: '#FD7D0A'
    },
    error: {
        main: '#e55026',
        dark: '#b93a16'
    },
    cta: {
        main: '#FD7D0A',
        dark: '#FD7D0A',
        red: '#F20000'
    },
    grey: {
        100: '#f0f2f6',
        200: '#DCE1E8',
        300: '#7d98aa',
        400: '#6d818d',
        500: '#51646f',
        600: '#36444d',
        700: '#ABB3BF',
        800: '#6d808d'
    },
    white: {
        main: '#fff'
    },
    black: {
        main: '#000'
    },
    grayscale: {
        main: '#fafafa'
    },
    tile: {
        text: '#000'
    },
    discount: {
        text: '#C20344',
        label: '#C20344'
    },
    icons: {
        usp: '#10BA60'
    },
    wizard: {
        dark: '#51646f'
    }
};

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.mainTwo
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.mainTwo
        }
    },
    checkbox: {
        active: palette.cta.main
    },
    cta: {
        main: {
            background: {
                primary: palette.cta.main
            },
            text: {
                primary: palette.white.main
            },
        },
        disabled: {
            background: {
                primary: '#e4e4e4 !important'
            }
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.mainTwo
            }
        },
        chip: {
            background: {
                active: palette.grey['100']
            },
            border: {
                active: palette.grey['100']
            },
            text: {
                active: palette.primary.main
            }
        },
        radio: {
            border: {
                active: palette.primary.mainTwo
            },
            control: {
                active: palette.primary.mainTwo
            },
        },
        card: {
            border: {
                active: palette.primary.mainTwo
            }
        },
        check: {
            border: {
                active: palette.primary.mainTwo
            }
        },
        tile: {
            border: {
                active: palette.primary.mainTwo
            },
            mark: {
                background: {
                    primary: palette.primary.mainTwo,
                    active: palette.primary.mainTwo
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#000',
                    active: '#000'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.grey['100']
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            }
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let theme: any = createTheme({
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily('Akkurat', {
            fontSize: 14,
            h1: {
                fontSize: '2rem',
            },
            h2: {
                fontSize: '1.625rem'
            },
            h3: {
                fontSize: '1.375rem'
            },
            h4: {
                fontSize: '1.125rem'
            },
        }),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 423,
            md: 680,
            lg: 991,
            xl: 1100,
        }
    }
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Akkurat';
                  src: url('${ Akkurat }') format('woff2');
                }
                @font-face {
                  font-family: 'Akkurat';
                  src: url('${ AkkuratBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '.sawlist-btn': {
                        'button': {
                            width: '100%',
                            marginBottom: '20px',
                            fontSize: 16,
                            '.sawlist-counter': {
                                display: 'flex',
                                backgroundColor: theme.palette.cta.main,
                                color: '#fff',
                                minWidth: '30px',
                                minHeight: '30px',
                                fontSize: '14px',
                                lineHeight: '14px',
                                fontWeight: 'bold',
                                borderRadius: '20px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                //marginLeft: '10px'
                            }
                        }
                    },
                    '&.sawlist-left': {
                        borderRight: '0 !important',
                        [theme.breakpoints.down('lg')]: {
                            paddingRight: 0,
                            border: 'none',
                            borderRight: 'none!important'
                        },
                    },
                    '.order-overview-gamma': {
                        border: `1px solid ${theme.palette.grey[200]}`,
                        borderRadius: '5px',
                        padding: '10px',
                        '.end-price .price': {
                            fontSize: 52,
                            fontWeight: 'bold',
                            '.MuiTypography-root': {
                                fontSize: 52,
                                'sup': {
                                    'small': {
                                        fontSize: 22
                                    }
                                }
                            }
                        }
                    },
                    '.fixed-total': {
                        padding: '20px 40px',
                        '.order-overview-mobile-cta': {
                            margin: 0,
                            height: '42px',
                            padding: '8px!important',
                            minWidth: '158px',
                            //backgroundColor: theme.palette.cta.main,
                            fontSize: 16,
                            fontWeight: 'bold'
                        },
                        '[aria-label="circled-arrow"]': {
                            minWidth: '42px',
                            height: '42px',
                            padding: 0
                        }
                    },
                    '.gallery-container': {
                        '.image-gallery-slides,.image-gallery-thumbnail-image': {
                            borderRadius: 4,
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.rounded': {
                        borderRadius: '26px!important',
                        padding: '11px 21px!important',
                    },
                    '&.apply-dimensions': {
                        fontSize: 16,
                        background: theme.palette.cta.main,
                        borderRadius: '60px',
                        border: `2px solid ${ theme.palette.cta.main }`,
                        color: theme.palette.white.main,
                        fontWeight: 'bold',
                        minWidth: '122px'
                    },
                    '&.show-more': {
                        '.MuiButton-startIcon': {
                            marginRight: '4px'
                        }
                    },
                    '&.product-gamma.go-to-product': {
                        borderRadius: '20px!important',
                        padding: '11px 21px 13px 21px!important',
                        fontSize: 16,
                        backgroundColor: theme.palette.cta.main,
                        fontWeight: 'bold',
                        marginBottom: '0!important',
                        '&:hover': {
                            background: theme.palette.secondary.main
                        }
                    },
                    '&.gamma-go-back': {
                        width: 'auto',
                        height: '42px',
                        marginBottom: '44px',
                        border: `2px solid ${theme.palette.primary.main}`,
                        color: theme.palette.primary.main,
                        background: theme.palette.white.main,
                        fontWeight: 'bold',
                        fontSize: 16,
                        '&:hover': {
                            border: `2px solid ${theme.palette.primary.main}`,
                            color: theme.palette.primary.main,
                            background: theme.palette.white.main,
                        }
                    },
                    '&.add-to-sawlist-gamma': {
                        borderRadius: 100
                    },
                    '&.order-overview-cta.gamma': {
                        height: 48,
                        padding: '8px',
                        width: '100%',
                        marginTop: '24px',
                        marginBottom: 0,
                        backgroundColor: theme.palette.cta.main,
                        fontSize: 16,
                        fontWeight: 'bold'
                    },
                    '&.mobile-filter': {
                        '.filter-title': {
                            marginBottom: '-4px'
                        }
                    },
                    '&.Mui-disabled': {
                        '&[aria-label="sawlist"]': {
                            backgroundColor: 'transparent !important',
                        },
                        backgroundColor: `${theme.palette.grey[500]} !important`,
                        cursor: 'not-allowed',
                        '&.apply-dimensions': {
                            border: `2px solid ${theme.palette.grey[500]}`
                        }
                    },

                }
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '.MuiChip-label': {
                        fontSize: 11,
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '.catalog-product-skeleton': {
                        flexGrow: 1,
                        '.MuiSkeleton-root': {
                            '&:last-child': {
                                width: '100%!important'
                            }
                        }
                    },
                    '&.gamma-filter-title': {
                        '.MuiButton-root': {
                            fontSize: 14,
                            padding: 0,
                            '&[aria-label="title-collapse"]': {
                                p: {
                                    fontWeight: 'bold',
                                    fontSize: 20
                                }
                            }
                        },
                        '.MuiButton-endIcon': {
                            color: theme.palette.primary.main
                        }
                    },
                    '&.active-filters': {
                        '&.gamma': {
                            alignItems: 'center',
                            '.gamma': {
                                alignItems: 'center'
                            },
                            '.MuiCheckbox-root': {
                                paddingLeft: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0
                            },
                            '.MuiBox-root': {
                                fontSize: 14,
                                color: theme.palette.primary.main,
                                '.price': {
                                    fontWeight: 'initial',
                                    marginRight: '16px',
                                    '>.MuiBox-root': {
                                        fontSize: 14,
                                        fontWeight: 'initial',
                                        top: 0
                                    },
                                    '.MuiTypography-root': {
                                        fontSize: 14,
                                        position: 'relative',
                                        'sup': {
                                            position: 'absolute',
                                            top: '-3px'
                                        }
                                    }
                                }
                            }
                        },
                    },
                    '.MuiInputBase-root': {
                        '&[aria-label="dimension-length"]': {
                            height: '48px',
                            borderRadius: '8px'
                        }
                    },
                    '.order-overview-mobile.end-price': {
                        '.price': {
                            fontSize: 30,
                            fontWeight: 'bold',
                            '.MuiTypography-root': {
                                fontSize: 30,
                                'sup': {
                                    'small': {
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    },
                    '&.confirm-dialog': {
                        width: '100%',
                        padding: '16px 12px',
                        '.MuiButtonBase-root': {
                            background: 'none',
                            height: '48px',
                            fontSize: 14,
                            color: '#505971',
                            '&:first-of-type': {
                                background: theme.palette.error.main,
                                color: theme.palette.white.main
                            }
                        }
                    },
                    '.item-count': {
                        lineHeight: '17px'
                    },
                    '&.stepper-skeleton': {
                        margin: '30px 0 30px -132px',
                        [theme.breakpoints.down('lg')]: {
                            margin: '30px 0',
                            justifyContent: 'space-between',
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                width: '100%',
                                height: '3px',
                                left: 0,
                                background: theme.palette.grey[700],
                                top: '14px'
                            }
                        },
                        '.stepper-item': {
                            padding: '0 8px',
                            position: 'relative',
                            flex: '1',
                            alignItems: 'center',
                            [theme.breakpoints.down('lg')]: {
                                flex: 0,
                                padding: 0,
                                background: theme.palette.grey[700],
                                borderRadius: '50%',
                                '&:first-of-type': {
                                    borderRight: '5px solid white'
                                },
                                '&:last-of-type': {
                                    borderLeft: '5px solid white'
                                },
                                '&:nth-of-type(2)': {
                                    borderRight: '5px solid white',
                                    borderLeft: '5px solid white'
                                }
                            },
                            '.MuiSkeleton-rectangular': {
                                position: 'absolute',
                                height: '3px',
                                top: '14px',
                                flex: '1 1 auto',
                                left: 'calc(-50% + 20px)',
                                right: 'calc(50% + 20px)',
                                [theme.breakpoints.down('lg')]: {
                                    display: 'none'
                                },
                            },
                            '.MuiSkeleton-text': {
                                [theme.breakpoints.down('lg')]: {
                                    display: 'none'
                                },
                                width: '50px',
                                height: '20px',
                                marginTop: '13px'
                            },
                            '.MuiSkeleton-circular': {
                                width: '34px',
                                height: '34px'
                            }
                        }
                    },
                    '&.sawlist-product-content': {
                        width: '100%',
                        '.sawlist-product-edit': {
                            fontSize: '16px',
                            color: theme.palette.primary.main,
                            svg: {
                                stroke: theme.palette.primary.main
                            },
                            minWidth: 100,
                            textDecoration: 'underline',
                            '.MuiButton-endIcon': {
                                marginLeft: 7,
                                marginTop: -4
                            },
                            '&:hover': {
                                fontWeight: 600
                            }
                        }
                    },
                }
            }
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    '&.gamma-collapse': {
                        '.tooltip-icon': {
                            '.MuiIconButton-root': {
                                paddingLeft: 0,
                                paddingTop: 0,
                                marginLeft: '-2px'
                            },
                            '.tooltip-popover': {
                                top: -40
                            }
                        },
                        '.tooltip-icon:hover': {
                            textDecoration: 'underline'
                        },
                        '.MuiChip-root': {
                            borderRadius: '5px',
                            border: `1px solid ${ theme.palette.grey[200] }`,
                            padding: '8px 0',
                            '.MuiChip-label': {
                                color: theme.palette.primary.main
                            },
                            '&:hover': {
                                fontSize: 14,
                                borderColor: theme.palette.cta.dark,
                                background: `${ alpha(theme.palette.secondary.main, 0.1) } !important`,
                                '.MuiChip-label': {
                                    color: '#000'
                                }
                            },
                            '&.MuiChip-filled': {
                                fontSize: 14,
                                borderColor: theme.palette.cta.dark,
                                background: `${ alpha(theme.palette.secondary.main, 0.1) } !important`,
                                '.MuiChip-label': {
                                    color: '#000'
                                }
                            }
                        },
                        '.MuiCollapse-root': {},
                        '.price-gamma': {
                            marginBottom: '22px',
                            '.MuiFormControl-root': {
                                margin: 0,
                                width: '40%',
                                '.MuiInputBase-root': {
                                    height: '42px',
                                    borderRadius: '8px',
                                    paddingLeft: '6px',
                                    'input': {
                                        marginLeft: '-5px',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            '.MuiFormHelperText-root': {
                                color: theme.palette.black.main,
                                fontWeight: 100,
                                margin: '0 10px'
                            },
                            '.MuiButton-root': {
                                height: '42px',
                                borderRadius: '50%',
                                minWidth: '42px',
                                maxWidth: '42px',
                                border: `1px solid ${ theme.palette.primary.main }`,
                                color: theme.palette.primary.main,
                                marginLeft: '10px'
                            }
                        },
                        '.slider-gamma': {
                            '.MuiSlider-rail': {
                                backgroundColor: theme.palette.grey['700']
                            },
                            '.MuiSlider-mark': {
                                backgroundColor: theme.palette.grey['700']
                            },
                            '.price': {
                                '>.MuiBox-root': {
                                    top: 0,
                                    fontWeight: 'bold'
                                },
                                '.MuiTypography-root': {
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    'sup': {
                                        fontWeight: 'initial'
                                    }
                                }
                            }
                        }
                    },
                    '.more-info': {
                        marginLeft: '10px',
                        marginTop: '1px',
                        color: theme.palette.primary.main
                    },
                    '.facet-value': {
                        justifyContent: 'flex-start',
                        '.MuiBox-root': {
                            fontWeight: 'bold',
                            position: 'relative',
                            color: theme.palette.black.main,
                            marginLeft: '-10px',
                            '&:after': {
                                content: `')'`
                            },
                            '&:before': {
                                content: `'('`
                            }
                        }
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '.MuiTypography-root': {
                        fontSize: 14,
                        marginTop: '1px'
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '.MuiTypography-root': {
                        '&.product-title': {
                            fontSize: '1.2rem',
                            //lineHeight: '1.87'
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.product-price-gamma': {
                        '.MuiTypography-h5': {
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                        }
                    },
                    '&.product-dimensions-gamma': {
                        color: theme.palette.grey['800'],
                        marginBottom: '15px',
                        '.product-sizes': {
                            color: theme.palette.black.main,
                            fontSize: 14
                        }
                    },
                    '&.filter-value': {
                        fontWeight: 'bold'
                    },
                    '&.pdp-title-gamma': {
                        fontWeight: 'bold',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: 24,
                            marginBottom: '20px'
                        }
                    },
                    '&.pdp-category-gamma': {
                        color: theme.palette.black.main,
                        fontSize: 16
                    },
                    '&[aria-label="pdp-title-price"]': {
                        fontSize: 18,
                        fontWeight: 'bold',
                        '.MuiTypography-root': {
                            fontSize: 52,
                            fontWeight: 'bold',
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 30
                            },
                            'sup': {
                                'small': {
                                    fontSize: 22,
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: 14
                                    },
                                }
                            }
                        }
                    },
                    '&.total-price.gamma': {
                        '.price': {
                            display: 'flex',
                            alignItems: 'center',
                            '.MuiTypography-root': {
                                display: 'flex',
                                marginTop: '2px'
                            }
                        }
                    },
                    '&.product-price': {
                        '.price': {
                            '.MuiTypography-root': {
                                fontSize: '30px',
                                'sup': {
                                    'small': {
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    },
                    '&.discount-info': {
                        color: theme.palette.grey[300],
                        marginBottom: '12px',
                        marginTop: '20px'
                    },
                    '&.popup-title': {
                        marginBottom: '20px'
                    },
                    '&.discount-label': {
                        background: theme.palette.discount.label
                    },
                    '&.checkout-usp': {
                        paddingTop: '2px!important'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.product-card': {
                        //borderRadius: '5px',
                        borderRadius: 12,
                        borderColor: theme.palette.grey[200]
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li': {
                            '.MuiButtonBase-root': {
                                width: '36px',
                                height: '36px',
                                margin: 0,
                                color: theme.palette.primary.main,
                                borderColor: theme.palette.grey[200],
                                borderLeft: 'none',
                                fontWeight: 'bold',
                                borderRadius: 0,
                                '&.Mui-selected, &:hover': {
                                    background: `${ theme.palette.primary.main }!important`,
                                    color: theme.palette.white.main
                                }
                            },
                            '&:first-of-type': {
                                '.MuiButtonBase-root': {
                                    borderRadius: '5px 0 0 5px',
                                    borderLeft: `1px solid ${ theme.palette.grey[200] }`
                                }
                            },
                            '&:last-of-type': {
                                '.MuiButtonBase-root': {
                                    borderRadius: '0 5px 5px 0'
                                }
                            },
                            'div': {
                                width: '36px',
                                height: '36px',
                                margin: 0,
                                borderRight: `1px solid ${theme.palette.grey[200]}`
                            }
                        }
                    }
                }
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    margin: '30px 0 30px -132px',
                    [theme.breakpoints.down('lg')]: {
                        margin: '30px 0',
                        justifyContent: 'space-between',
                        position: 'relative',
                        '.MuiStep-root': {
                            '.MuiStepConnector-root': {
                                display: 'none',
                            },
                            '&:first-of-type': {
                                flex: 0,
                                '&.Mui-completed': {
                                    '&+.MuiStep-root': {
                                        '&::before': {
                                            background: theme.palette.primary.main
                                        }
                                    }
                                }
                            },
                            '&:last-of-type': {
                                flex: 0
                            },
                            '&:nth-of-type(2)': {
                                borderLeft: `5px solid white`,
                                borderRight: `5px solid white`,
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    height: '3px',
                                    background: theme.palette.grey[700],
                                    top: '14px',
                                    left: 'calc(0% - 6px)',
                                    right: 'calc(50% + 22px)'
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    height: '3px',
                                    background: theme.palette.grey[700],
                                    top: '14px',
                                    left: 'calc(50% + 22px)',
                                    right: 'calc(0% - 6px)'
                                },
                                '&.Mui-completed': {
                                    '&::after': {
                                        background: theme.palette.primary.main
                                    }
                                }
                            }
                        }
                    },
                    '.MuiStepLabel-root': {
                        '.MuiStepLabel-iconContainer.Mui-completed': {
                            width: '32.8px',
                            height: '32.8px',
                            border: `2px solid ${theme.palette.primary.main}`,
                            background: `url(${doneIcon})`,
                            backgroundSize: 'contain',
                            '.MuiSvgIcon-root': {
                                display: 'none'
                            }
                        },
                        '.MuiSvgIcon-root': {
                            width: 28,
                            height: 28,
                            fontSize: 28
                        },
                        '.MuiStepLabel-labelContainer': {
                            [theme.breakpoints.down('lg')]: {
                                display: 'none'
                            },
                            '.MuiStepLabel-label': {
                                fontWeight: 'bold',
                                fontSize: 13,
                                color: theme.palette.grey[700],
                                '&.Mui-completed, &.Mui-active': {
                                    color: theme.palette.primary.main
                                },
                            }
                        }
                    },
                    '.MuiStepConnector-root': {
                        top: '14px',
                        '.MuiStepConnector-line': {
                            borderTopWidth: '3px',
                            borderColor: theme.palette.grey[700]
                        },
                        '&.Mui-active, &.Mui-completed': {
                            '.MuiStepConnector-line': {
                                borderColor: theme.palette.primary.main
                            },
                        }
                    },
                    '.MuiStepLabel-iconContainer': {
                        '&:not(.Mui-active)': {
                            border: `2px solid ${theme.palette.grey[200]}`,
                            borderRadius: '50%',
                            '.MuiSvgIcon-root': {
                                'circle': {
                                    fill: 'transparent'
                                },
                                'text': {
                                    fill: theme.palette.grey[200]
                                }
                            }
                        },
                        '&.Mui-active': {
                            '.MuiSvgIcon-root': {
                                width: 32,
                                height: 32
                            }
                        }
                    },
                    '.MuiSvgIcon-root': {
                        color: theme.palette.grey[200],
                        '&.Mui-active': {
                            color: theme.palette.primary.main,
                        }
                    }
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: '1290px!important',
                    '.progress-bar': {
                        maxWidth: '850px',
                        [theme.breakpoints.down('lg')]: {
                            maxWidth: '100%'
                        }
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '&.grid-feedback-tile': {
                        borderRadius: '5px',
                        border: `1px solid ${ theme.palette.grey[200] }`,
                        backgroundColor: theme.palette.grey[200],
                    },
                    '.grid-feedback-tile-subtitle': {
                        opacity: 0.7
                    },
                    '.grid-feedback-tile-confirm-button': {
                        background: theme.palette.secondary.main,
                        borderRadius: 20,
                        marginBottom: 0
                    },
                    '.grid-feedback-tile-input': {
                        background: `${ theme.palette.primary.light }!important`,
                        borderRadius: '8px',
                        'label,textarea': {
                            color: theme.palette.tile.text,
                            opacity: 0.7,
                            padding: '0!important'
                        },
                        'fieldset,fieldset:hover': {
                            borderColor: theme.palette.primary.light
                        }
                    }
                }
            }
        },
    }
});

theme = createTheme(BaseTheme, theme);

export default createTheme(theme, ThemeComponents(theme));
