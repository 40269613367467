import { alpha, createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Raleway from '@fonts/Raleway-Regular.woff2';
import RalewayBold from '@fonts/Raleway-Bold.woff2';
import RalewaySemiBold from '@fonts/Raleway-SemiBold.woff2';

const primaryMain = '#60b331';

let palette = {
    primary: {
        main: primaryMain,
        light: alpha(primaryMain, 0.1),
        lightTwo: '#f0f7ed',
        dark: '#00561B'
    },
    secondary: {
        main: '#FFD449'
    },
    text: {
        primary: '#1c1c1c',
        secondary: '#3c3c3c',
        disabled: '#a8b3bf'
    },
    grey: {
        100: '#a5da79',
        300: '#71767a',
        lightOne: '#cfcecf',
    },
    white: {
        main: '#fff',
        lightOne: '#f2f2f2'
    },
    black: {
        main: '#000'
    },
    red: {
        main: '#FF0000'
    },
    divider: '#c4c4c4',
    tonalOffset: 0.1,
    checklist: {
        main: '#fff',
        light: '#60B331'
    },
    wizard: {
        dark: primaryMain
    }
}

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: palette.primary.main
    },
    cta: {
        main: {
            background: {
                primary: palette.primary.main
            },
            text: {
                primary: palette.white.main
            },
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            }
        },
        chip: {
            background: {
                active: palette.primary.lightTwo
            },
            border: {
                active: palette.primary.lightTwo
            },
            text: {
                active: '#000'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
        },
        card: {
            border: {
                active: palette.primary.main
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#000',
                    active: '#000'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            }
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let theme: any = createTheme({
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily('Raleway', {
            fontSize: 14,
        }),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 423,
            md: 680,
            lg: 991,
            xl: 1336,
        },
    }
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Raleway';
                  src: url('${ Raleway }') format('woff2');
                }
                @font-face {
                  font-family: 'RalewayBold';
                  src: url('${ RalewayBold }') format('woff2');
                }
                @font-face {
                  font-family: 'RalewaySemiBold';
                  src: url('${ RalewaySemiBold }') format('woff2');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '.MuiButton-root.MuiButton-contained': {
                        '&:hover': {
                            background: theme.palette.primary.main
                        }
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '&.product-card': {
                        '.price': {
                            'span, small, &~span': {
                                fontSize: '1.25rem'
                            },
                            '&~span': {
                                paddingLeft: '5px'
                            }
                        }
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '.MuiTypography-root': {
                        '&.product-title': {
                            fontSize: '1.25em',
                        },
                        '&.product-subtitle, &.catalog-price-perm2': {
                            fontFamily: 'RalewayBold',
                            fontSize: 14,
                            color: theme.palette.black.main
                        },
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.header-title': {
                        fontFamily: 'RalewayBold',
                        fontSize: '2.250rem',
                        '~.MuiTypography-root': {
                            textAlign: 'center'
                        }
                    },
                    '&.catalog-total': {
                        fontFamily: 'RalewayBold'
                    },
                    '&.promo-label': {
                        background: theme.palette.primary.dark,
                        fontSize: 14,
                        bottom: 10,
                        borderRadius: 0
                    },
                    '&[aria-label="pdp-title-category"], &[aria-label="pdp-title-price"]': {
                        fontSize: 18,
                        lineHeight: '28px',
                        color: theme.palette.black.main
                    },
                    '&[aria-label="pdp-title-price"]': {
                        fontFamily: 'RalewayBold'
                    },
                    '&.sawlist-title': {
                        fontSize: 24,
                        fontFamily: 'RalewayBold',
                        color: theme.palette.black.main
                    },
                    '&.sawlist-title-total': {
                        fontSize: 14,
                        lineHeight: 2
                    },
                    '&.total-price': {
                        '.price': {
                            '.MuiTypography-root': {
                                fontSize: 14,
                                fontFamily: 'RalewayBold',
                                color: theme.palette.text.primary
                            }
                        }
                    },
                    '&.product-price': {
                        fontSize: 24,
                        fontFamily: 'RalewayBold',
                        color: theme.palette.primary.main,
                        small: {
                            fontSize: '1em',
                            fontFamily: 'RalewayBold',
                        },
                        '.price': {
                            color: theme.palette.primary.main,
                        }
                    },
                    '.price': {
                        fontFamily: 'RalewayBold',
                        color: theme.palette.black.main,
                        '.price-symbol': {
                            display: 'none'
                        },
                        small: {
                            fontSize: '1em'
                        }
                    },
                    '&.end-price': {
                        '.price': {
                            color: theme.palette.primary.main
                        }
                    },
                    '&.popup-title': {
                        fontFamily: 'RalewayBold',
                        marginBottom: '20px'
                    },
                    '&[aria-label="small-text"] a': {
                        color: theme.palette.black.main
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.usp-object': {
                        '.MuiSvgIcon-root': {
                            width: '1.2em',
                            height: '1.2em'
                        }
                    },
                    '.price-slider': {
                        width: '90%',
                        margin: '0 auto',
                        marginTop: '24px',
                        '.MuiSlider-root': {
                            color: theme.palette.grey.lightOne,
                            '.MuiSlider-thumb': {
                                background: theme.palette.white.main,
                                border: `2px solid ${ theme.palette.primary.main }`,
                                width: '24px',
                                height: '24px'
                            }
                        },
                        '.MuiSlider-track': {
                            color: theme.palette.primary.main
                        },
                        '.MuiSlider-rail, .MuiSlider-mark': {
                            background: theme.palette.grey.lightOne
                        },
                        '.MuiSlider-markLabel': {
                            '&[data-index="0"]': {
                                transform: 'translateX(-24px)'
                            },
                            '&[data-index="1"]': {
                                transform: 'translateX(calc(-100% + 24px))'
                            },
                            '.price': {
                                '.MuiTypography-root': {
                                    fontSize: 14,
                                },
                                '.MuiBox-root': {
                                    top: 0
                                }
                            }
                        }
                    },
                    '.product-variants-title': {
                        fontFamily: 'RalewayBold',
                        fontSize: '14px',
                        color: theme.palette.black.main
                    },
                    '&.sawlist-product-title': {
                        '.MuiTypography-root h5': {
                            fontFamily: 'RalewayBold',
                            fontSize: 24,
                            color: theme.palette.black.main
                        }
                    },
                    '&.confirm-dialog': {
                        width: '100%',
                        padding: '16px 12px',
                        '.MuiButtonBase-root': {
                            background: 'none',
                            height: '48px',
                            fontSize: 14,
                            color: theme.palette.text.primary,
                            '&:first-of-type': {
                                background: theme.palette.red.main,
                                color: theme.palette.white.main
                            }
                        }
                    },
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li': {
                            '.MuiPaginationItem-root': {
                                fontSize: 12,
                                // lineHeight: 14,
                                border: `1px solid ${ theme.palette.primary.main }`,
                                borderRadius: 0,
                                color: theme.palette.black.main,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: `${ theme.palette.primary.main } !important`,
                                        color: theme.palette.white.main
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.white.main,
                    '&[aria-label="title-collapse"]': {
                        fontFamily: 'RalewayBold'
                    },
                    '&[aria-label="show-more"]': {
                        color: theme.palette.text.primary,
                        fontFamily: 'RalewaySemiBold'
                    },
                    '&.forestea-go-back': {
                        background: theme.palette.primary.main,
                        color: theme.palette.white.main,
                        fontFamily: 'RalewayBold',
                        width: 200,
                        height: 48
                    },
                    '&.add-to-sawlist-gamma': {
                        borderRadius: 0,
                        'p, svg': {
                            color: theme.palette.primary.dark
                        }
                    },
                    '&.content-page-paragraph-button': {
                        background: theme.palette.primary.dark
                    }
                }
            }
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    '.active-filters': {
                        color: theme.palette.primary.main,
                        '.MuiButtonBase-root': {
                            '&[aria-label="close"]': {
                                color: theme.palette.primary.main
                            }
                        }
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.sorting' : {
                        '.MuiFormLabel-root, .MuiSelect-select': {
                            fontFamily: 'RalewayBold'
                        },
                        '.MuiInputBase-root': {
                            borderRadius: 0
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary
                        }
                    },
                    '&[aria-label="dimension-length"], &[aria-label="dimension-width"]': {
                        '.MuiFormHelperText-root': {
                            fontFamily: 'RalewayBold'
                        }
                    },
                    '&[aria-label="price-slider-inputs"]': {
                        '.MuiFormHelperText-root': {
                            fontFamily: 'Raleway'
                        },
                        '.MuiInputBase-root': {
                            '.MuiInputAdornment-root': {
                                display: 'none'
                            }
                        }
                    }
                }
            }
        }
    }
});

theme = createTheme(BaseTheme, theme);

export default createTheme(theme, ThemeComponents(theme));
