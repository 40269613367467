import React from 'react';
import { useEffectOnce } from 'react-use';
import { mapValues } from 'lodash';
import type { Configuration, Configurator, ConfiguratorOptionValue } from '@models';
import { usePDPContext } from '@context/PDPContext';
import {
    calculateActiveConfigurator,
    calculatePrice,
    getSettingsFor3dView,
    makeDefaultConfiguration,
    syncConfiguration,
    trackIvenzaConfiguratorOdinFormStep,
    mapProductLine,
    getConfiguratorStepNameByValue,
} from '@helpers';

interface ConfiguratorContextProps {
    configurator: Configurator | null;
    configuration: Configuration;
    setOptionValue: (key: string, value: ConfiguratorOptionValue) => void;

    price: number;
    setPrice: (price: number) => void;

    isLoading: boolean;
    setIsLoading: React.Dispatch<boolean>;
}

const defaultContext: ConfiguratorContextProps = {
    configurator: null,
    configuration: {},
    setOptionValue: (key: string, value: ConfiguratorOptionValue) => {},

    price: 0,
    setPrice: (price: number) => {},

    isLoading: false,
    setIsLoading: (value: boolean) => {},
};

export const ConfiguratorContext = React.createContext(defaultContext);

interface ConfiguratorProviderProps {
    configurator: Configurator;
    configuration: Configuration | null;
    children: React.ReactNode;
    material: { code: string, value: string };
    isLoading: boolean;
    setIsLoading: React.Dispatch<boolean>;
}

export const ConfiguratorProvider = ({
    configurator,
    configuration: data,
    material,
    children,
    isLoading,
    setIsLoading,
}: ConfiguratorProviderProps) => {
    const
        { product, price, setPrice, viewer3dRef, isViewer3dLoaded, updateProductContext, setConfigurator } = usePDPContext(),
        viewer = viewer3dRef.current,
        [ configuration, setConfiguration ] = React.useState<Configuration>(
            data || makeDefaultConfiguration(configurator, material),
        ),
        [ activeConfigurator, setActiveConfigurator ] = React.useState<Configurator>(
            calculateActiveConfigurator(configurator, configuration),
        );

    const
        setOptionValue = (key: string, value: ConfiguratorOptionValue) => {
            setConfiguration(configuration => {
                const
                    newConfiguration = { ...configuration, [key]: value }, // apply user change
                    newConfigurator = calculateActiveConfigurator(configurator, newConfiguration), // apply conditions to the configurator tree
                    calculatedPrice = calculatePrice(newConfiguration, newConfigurator) / 100;

                trackIvenzaConfiguratorOdinFormStep(
                    mapProductLine(product.product_line),
                    'change_form',
                    [ {
                        field_name: key,
                        from: configuration[key].code,
                        to: newConfiguration[key].code,
                    } ],
                    {
                        product_id: product.url,
                        configuration: mapValues(newConfiguration, item => item.code),
                        price: calculatedPrice,
                        discount: product.discount ? calculatedPrice * (product.discount / 100) : 0,
                        totalPrice: product.discount ? calculatedPrice * (1 - product.discount / 100) : calculatedPrice,
                    },
                    {
                        step_name: getConfiguratorStepNameByValue(newConfigurator, value),
                    },
                );

                // update configurator tree
                setActiveConfigurator(newConfigurator);

                // apply default value & clean up conditional configuration
                return syncConfiguration(newConfiguration, newConfigurator);
            });

            updateProductContext(key, value.code);
        },
        update3dView = () => {
            if (viewer && isViewer3dLoaded)
                viewer.update3dViewSettings(getSettingsFor3dView(activeConfigurator, configuration));
        };

    useEffectOnce(() => setConfiguration(configuration =>
        syncConfiguration(
            configuration,
            calculateActiveConfigurator(configurator, configuration),
        ),
    ));

    React.useEffect(() => {
        setPrice(calculatePrice(configuration, activeConfigurator));

        setConfigurator(activeConfigurator);

        update3dView();
    }, [ activeConfigurator ]);

    React.useEffect(() => update3dView(), [ isViewer3dLoaded ]);

    return (
        <ConfiguratorContext.Provider
            value={ {
                configurator: activeConfigurator,
                configuration,
                setOptionValue,
                price,
                setPrice,
                isLoading,
                setIsLoading,
            } }
        >
            { children }
        </ConfiguratorContext.Provider>
    );
};
