import { useLocale } from '@hooks/common';
import { mapProductLine, MultiLanguageHelper, productLines } from '@helpers';

export const useProductName = (data, withThickness = true): { title: string, subtitle?: string } => {
    const
        locale = useLocale(),
        ML = new MultiLanguageHelper(data, locale);

    switch (mapProductLine(data.product_line)) {
        case productLines.flexscreen:
            return {
                title: ML.getField('article_type'),
                subtitle: ML.getField('camper_type'),
            };
        case productLines.insect:
            return {
                title: ML.getField('article_type'),
                subtitle: undefined,
            };
        default:
            return {
                title: [
                    ML.getField('material_type'),
                    withThickness ? data.product_thickness : undefined,
                ].filter(Boolean)
                    .join(' '),
                subtitle: ML.getField('material_category'),
            };
    }
};
